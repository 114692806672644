// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-onlyfans-index-js": () => import("./../src/pages/onlyfans/index.js" /* webpackChunkName: "component---src-pages-onlyfans-index-js" */),
  "component---src-pages-presskit-index-js": () => import("./../src/pages/presskit/index.js" /* webpackChunkName: "component---src-pages-presskit-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */)
}

